<template>
    <div ref="bbox" style="background: #fff">
        <!--<mySearch></mySearch>-->
        <div class="wd11200">
            <div class="crumb-wrap">
                <ul class="cl">
                    <li>首页<i>></i> </li>
                    <li>{{JSON.stringify(goodsData) == "{}" ? '':goodsData.categoryOneName}}<i>></i> </li>
                    <li>{{JSON.stringify(goodsData) == "{}" ?'':goodsData.categoryTwoName}}<i></i> </li>
                    <!--<li>漫步者立体蓝牙耳机<i>></i></li>-->
                </ul>
            </div>
            <div class="clearfix goods_details ">
                <div class="img_details fl">

                    <div class="pic-box">
                        <pic-zoom :url="imgPath+goodsData.pics[checkImg]" :scale="3"></pic-zoom>
                    </div>
                    <div class="spec-list mt10">
                        <span @click="pricBtn"></span>
                        <div class="ml20">
                            <ul v-bind:style="styleObject">
                                <li v-for="(item,index) in goodsData.pics" @click="clickImg(index)"
                                    :class="{'active':index==checkImg}"><img :src="imgPath+item" alt=""></li>
                            </ul>
                        </div>
                        <span @click="nextBtn"></span>
                    </div>
                </div>
                <div class="fl text_detail">
                    <h2>{{goodsData.goodsTitle}}</h2>
                    <div class="pricr_text">
                        &yen;<strong>{{goodsData.marketPrice}}</strong>
                        <em v-if="isShowPrice">成本价：{{goodsData.apiSalePrice}}</em>
                        <em v-if="!isShowPrice" @click="goLogin">登录后查看成本价</em>
                    </div>
                    <!-- {{goodsData}} -->
                    <div class="spece clearfix" v-for="(item,index) in skuList">
                        <strong>{{item.skuName}}:</strong>
                        <ul class="fl clearfix">
                            <li :class="{active:checkIndex == index }" @click="selectIndex(index)"
                                v-for="(item,index) in item.skuValue">
                                {{item}}
                            </li>
                        </ul>
                    </div>
                    <div class="spece clearfix" style="padding-top: 10px;">
                        <strong style="padding-top: 11px;">配送区域:</strong>
                        <div>
                            <!-- <el-select v-model="value" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select> -->
                            <el-cascader v-model="value" :options="options" @change="handleChange"></el-cascader>
                        </div>
                    </div>
                    <div class="spece clearfix" style="padding-top: 10px;">
                        <strong style="padding-top: 11px;">运费:</strong>
                        <div style="padding-top: 11px;">
                            <p v-if="isShowPrice">{{freight}}</p>
                            <p v-if="!isShowPrice" @click="goLogin">登录后查看</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="goods_img_d wd11200">
            <h2>商品详情</h2>
            <div v-html="goodsData.details" class="pb20">
                <img src="http://7dd-statics.7dingdong.com/////data/files/image/20190627/20190627114241_83346.jpg"
                    alt="">
            </div>
        </div>
        <!--   底部    -->
        <myFooter></myFooter>
    </div>
</template>
<style lang="less" scoped>
    .pic-box {
        width: 400px;
        height: 400px;
        border: 1px solid #eee;
    }

    .wd11200 {
        width: 1200px;
        margin: 0 auto;
    }

    .crumb-wrap {
        padding: 19px 0;
        position: relative;
        margin-bottom: 18px;
    }

    .crumb-wrap li {
        float: left;
        font-size: 14px;
        line-height: 12px;
        color: #8A8A8A;
    }

    .crumb-wrap li:first-child {
        color: #323232;
    }

    .crumb-wrap li:first-child i {
        color: #323232;
    }

    .crumb-wrap li i {
        padding: 0 4px;
    }

    .crumb-wrap p {
        position: absolute;
        bottom: 10px;
        right: 0;
        font-size: 16px;
        color: #969696;
        vertical-align: middle;
    }

    .crumb-wrap p i {
        width: 21px;
        height: 20px;
        display: inline-block;
        line-height: 20px;
        margin-right: 10px;
        /*background: transparent url(../../../static/xq_btn_share.png) center center no-repeat;*/
    }

    .spec-list div {
        width: 440px;
        height: 80px;
        overflow: hidden;
        position: relative;
    }

    .spec-list li {
        width: 78px;
        height: 78px;
        margin-bottom: 10px;
        float: left;
        margin-right: 12px;
        border: 1px solid transparent;
    }

    .spec-list li.active {
        border: 1px solid #E94729;
    }

    .spec-list li:hover {
        border: 1px solid #E94729;
    }

    .spec-list {
        position: relative;
        margin-right: 40px;
    }

    .spec-list li img {
        width: 78px;
        height: 78px;
        line-height: 78px;
        display: block;
        border: 0 none;
    }

    .spec-list span {
        width: 16px;
        height: 28px;
        margin: 0 auto 4px;
        transform: rotate(90deg);
        background: url(../../assets/images/xq_btn_down.png) center center no-repeat;
    }

    .spec-list span:first-child {
        position: absolute;
        top: 20px;
        left: 0;
    }

    .spec-list span:last-child {
        position: absolute;
        top: 20px;
        right: 0;
        padding-top: 10px;
        margin-bottom: 0;
        transform: rotate(-90deg);
        background: url(../../assets/images/xq_btn_down.png) center center no-repeat;
    }

    .img_details {
        width: 440px;
    }

    .text_detail {
        width: 760px;
        text-align: left;

        h2 {
            color: #333333;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 22px;
        }

        .pricr_text {
            background: #F7F5F5;
            padding: 20px 0 20px 22px;
            text-align: left;
            font-size: 25px;
            color: #E60026;
            margin-bottom: 24px;

            strong {
                color: #E60026;
                font-size: 30px;
                font-weight: bold;
                margin-right: 19px;
            }

            em {
                color: #EC9314;
                font-size: 14px;
                cursor: pointer;
            }
        }

        .spece {
            display: flex;

            strong {
                color: #999999;
                font-size: 14px;
                margin-right: 20px;
                display: block;
            }
        }

        ul {
            width: 90%;

            li {
                float: left;
                min-width: 100px;
                box-sizing: border-box;
                padding: 0 20px;
                height: 22px;
                line-height: 22px;
                text-align: center;
                border: 1px solid #E5E5E5;
                font-size: 14px;
                color: #999999;
                margin-right: 20px;
                margin-bottom: 10px;

                &.active {
                    background: #EC1430;
                    color: #fff;
                }
            }
        }

    }

    .goods_details {
        padding-bottom: 30px;
        border-bottom: 1px solid #EEEEEE;
    }

    .goods_img_d h2 {
        padding: 30px 0;
        color: #333333;
        font-size: 24px;
        font-weight: bold;
    }
</style>
<script>
    import {getSign, listSign, getPcFreight} from '@/global/https'

    import {getGoodsDetail, getDescription, addNum} from '@/views/details/detailApi'
    import footer from '@/components/footer'
    import PicZoom from 'vue-piczoom'
    import city from '@/views/details/city'
    console.log(city)
    export default {
        components: {
            PicZoom,
            myFooter: footer, // 底部
        },
        data() {
            return {
                options: [],
                value: [],
                freight: 0,
                isShowPrice: true, // 是否显示成本价
                checkImg: 0,//选中小图index
                Zoom: true,//放大镜显示否
                styleObject: {//小图上遮罩块位置
                    position: 'absolute',
                    top: 0 + 'px',
                },
                listSign: [],//规格
                maxImg: [],
                miniImg: [],
                skuList: [],
                next: 0,
                acount: 1,//商品数量
                goodsId: 0,//商品id
                goodsData: {},
                checkIndex: 0,//选中规格
                detailImg: '',
            }
        },
        mounted() {
            console.log(this.$route.query, '222222')
            this.goodsId = this.$route.query.id;
            this.addNum();
            this.getGoodsDetail();
            this.getDescription();
            this.listSignConent()
            // 是否显示成本价
            let cookie = JSON.parse(localStorage.getItem('cookie') || '{}')
            if (cookie.tokenId) {
                this.isShowPrice = true
            } else {
                this.isShowPrice = false
            }
            this.buildtree(city, this.options, 1)
        },
        methods: {
            async handleChange(value) {
                console.log(this.listSign[this.checkIndex])
                let {freight} = await getPcFreight({
                    skuId: this.listSign[this.checkIndex].id,
                    parentAddress: value[value.length - 1]
                })
                this.freight = freight
                console.log(freight)
            },
            buildtree(list, arr, parentId, parentName) {//省市区
                list.forEach(item => {
                    if (item.parentId == parentId) {
                        var child = {
                            key: item.id,
                            value: item.parentPath,
                            label: item.name,
                            disabled: false,
                            children: item.parentPath.split(',').length != 3 ? [] : false
                        }
                        this.buildtree(list, child.children, item.id, item.name)
                        arr.push(child)
                    }
                })
            },
            clickImg(index) { //点击小图显示大图
                this.checkImg = index;
            },
            nextBtn() {
                if (this.next + 5 >= this.miniImg.length) {
                    return;
                } else {
                    this.next++;
                    this.styleObject.top = -this.next * 90 + 'px';
                }
            },
            pricBtn() {
                if (this.next == 0) {
                    this.styleObject.top = 0 + 'px';
                    return;
                } else if (this.next == 1) {
                    this.next--;
                    this.styleObject.top = this.next * -90 + 'px';
                } else {
                    this.next--;
                    this.styleObject.top = this.next * -90 + 'px';
                }
            },
            selectIndex(index) {
                this.checkIndex = index;
            },
            async addNum() {
                // let {message, code, data, status}= await addNum({goods_id:this.goodsId});
            },
            async getGoodsDetail() {
                let goodsData = await getSign({id: this.goodsId});
                console.log(goodsData, '-----------------1111111111111')
                this.goodsData = goodsData;
            },
            async listSignConent() {//规格
                let {code, msg, total, rows, pageNum, } = await listSign({goodsId: this.goodsId})
                this.listSign = rows
                this.listSign.forEach(item => {
                    let skuName = item.spec.split(':')[0]
                    let skuValue = item.spec.split(':')[1]
                    if (!this.skuList.length) {
                        this.skuList.push({skuName: skuName, skuValue: [skuValue]})
                        return false
                    }
                    this.skuList.forEach((sku, index) => {
                        if (sku.skuName == skuName) {
                            this.skuList[index].skuValue.push(skuValue)
                        } else {
                            this.skuList.push({skuName: skuName, skuValue: [skuValue]})
                        }
                    })
                    console.log(this.skuList)

                })
            },
            async getDescription() {
                let {message, code, data, status} = await getDescription({id: this.goodsId});
                if (status == 200) {
                    this.detailImg = data;
                }
            },
            goLogin() {
                this.$store.commit('changeShowLogin', 1);
            },

        }
    }
</script>